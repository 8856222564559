<template>
	<div class="table-edit-select">
		<el-popover placement="top" width="200" trigger="manual" v-model="editShow">
			<el-link :type="selectedType" slot="reference" @click="editShow = !editShow">{{selectedText}} <i
					class="el-icon-edit"></i></el-link>
			<div class="change-box">
				<el-select v-model="info[propsName]" size="mini" placeholder="请选择选项" clearable filterable
					style="width: 100%;" @change="setText" :filter-method="filterSelect">
					<el-option v-for="item in filterOptions" :key="item.id" :label="item.title" :value="item.id">
					</el-option>
				</el-select>
			</div>
			<div style="text-align: right; margin: 0">
				<el-button size="mini" type="text" @click="editShow = false">取消</el-button>
				<el-button type="primary" :loading="loading" size="mini" @click="sub">确定</el-button>
			</div>
		</el-popover>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				editShow: false,
				selectedText: '--',
				selectedType: 'primary',
				filterOptions: [],
				loading: false,
			}
		},
		props: {
			info: Object,
			propsName: String,
			options: Array,
			url: String
		},
		mounted() {
			if (this.options.length < 20) {
				this.options.forEach(option => {
					this.filterOptions.push(option)
				});
			}
			this.options.forEach(option => {
				if (option.id == this.info[this.propsName]) {
					this.selectedText = option.title
					this.selectedType = option.type ? option.type : 'primary'
				}
			});
		},
		methods: {
			setText(val) {
				this.options.forEach(option => {
					if (option.id == val) {
						this.selectedText = option.title
					}
				});
			},
			sub() {
				this.loading = true
				this.$util.put(this.url + '/' + this.info.id, {
					field: this.propsName,
					value: this.info[this.propsName]
				}).then((res) => {
					if (res.code != 0) {
						this.$message(res.msg ? res.msg : '操作失败')
					} else {
						this.editShow = false
						this.$emit('subQuick')
						this.loading = false;
					}
				})
			},
			filterSelect(val) {
				this.filterOptions = []
				this.options.forEach(option => {
					if (option.title.indexOf(val) >= 0) {
						this.filterOptions.push(option)
					}
				})
			}
		}
	}
</script>
<style scoped>
	.change-box {
		margin-bottom: 10px;
		display: inline-block;
	}

	.table-edit-select {
		display: inline-block;
	}
</style>
