<template>
  <div class="page" v-loading="loading">
    <el-form :inline="true" size="small">
      <el-form-item label="搜索">
        <el-input v-model="filter.keyword" placeholder="管理员姓名" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">查询</el-button>
        <el-button type="primary" @click="onCreate">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" border stripe :header-cell-style="{background:'#E4E7ED',color: '#606266'}"
      style="width: 100%;" size="small" max-height="500px" row-key="id" @filter-change="filterTable"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
      <el-table-column prop="id" label="ID" sortable width="100px" align="center"></el-table-column>
      <el-table-column label="头像" v-slot="{ row }" align="center">
        <el-image :src="row.photo" class="head-img"></el-image>
      </el-table-column>
      <el-table-column prop="name" label="管理员姓名" align="center"></el-table-column>
      <el-table-column prop="username" label="账号" align="center"></el-table-column>
      <el-table-column prop="roles" label="角色" align="center">
        <template slot-scope="scope">
          <el-tag type="primary" v-for="item in scope.row.roles" :key="item.id" disable-transitions
            style="margin-right: 5px; margin-bottom: 5px;">{{ item.title }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center"
        :filters="[{ text: '已启用', value: 1 },{ text: '已禁用', value: 0 },]" column-key="status">
        <template slot-scope="scope">
          <tableEditSelect propsName="status" @subQuick="getTabeData" :options="statusList" :info="scope.row"
            url="users/quick"></tableEditSelect>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-link @click="onEdit(scope.row)" type="primary" class="margin-r-10">修改</el-link>
          <el-popconfirm placement="top" icon-color="red" title="确定初始化密码为123456吗？" @confirm="onInitialize(scope.row)">
            <el-link type="warning" size="mini" slot="reference" class="margin-r-10">初始化密码</el-link>
          </el-popconfirm>
          <el-link v-if="systemUserId == scope.row.id" @click="onEditPass(scope.row)" type="primary"
            class="margin-r-10">修改密码</el-link>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="dialogTitle" :visible.sync="infoShow" width="600px" @close="resetInfo"
      :close-on-click-modal="false">
      <div class="head-container">
        <img :src="head" class="head-img">
        <div>
          <el-button @click="handleSelectHead" size="small" type="primary">选择头像</el-button>
        </div>
      </div>
      <el-form :model="info" ref="info" label-width="100px" :inline="false">
        <el-form-item prop="username" label="账号" v-if="saveType == 1">
          <el-input v-model="info.username" placeholder="请输入账号">
            <i slot="prefix" class="el-input__icon el-icon-user"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码" v-if="saveType == 1">
          <el-input type="password" v-model="info.password" placeholder="请输入密码">
            <i slot="prefix" class="el-input__icon el-icon-lock"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="name" label="用户名">
          <el-input v-model="info.name" placeholder="用户名"></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="info.roleIds" placeholder="选择角色" clearable filterable multiple style="width: 100%">
            <el-option v-for="item in roles" :key="item.id" :label="item.title + '[' + item.name + ']'"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="mobile" label="手机号">
          <el-input v-model="info.mobile" placeholder="请输入手机号码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="infoShow = false">取消</el-button>
        <el-button :loading="submitting" type="primary" @click="subInfo">提交</el-button>
      </span>

      <el-dialog title="选择头像" :visible.sync="showHeadDialog" append-to-body width="700px">
        <div class="dialog-head-container">
          <div v-for="item in userHeads" :key="item" @click="selectedHead = item" class="dialog-head-container_item">
            <img :src="item" class="dialog-head-container_img"
              :class="{'dialog-head-container_img--active': selectedHead == item}">
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showHeadDialog = false">取 消</el-button>
          <el-button @click="handleConfirmHead" type="primary">确 定</el-button>
        </span>
      </el-dialog>
    </el-dialog>
    <el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size"
      :current-page="current_page" @current-change="handleCurrentChange">
    </el-pagination>

    <el-dialog title="修改密码" :visible.sync="editPassShow" width="400px" @close="resetPass" :close-on-click-modal="false">
      <el-form :model="editPassForm" ref="editPass" label-width="70px" :rules="rules">
        <el-form-item prop="password" label="密码">
          <el-input type="password" v-model="editPassForm.password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item prop="confirm_password" label="密码">
          <el-input type="password" v-model="editPassForm.confirm_password" placeholder="请再次输入密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetPass">取 消</el-button>
        <el-button @click="handleConfirmEditPass" :loading="editSubmitting" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import tableEditSelect from "@/components/TableEditSelect"

  import {
    editUserPasswordAPI
  } from '@/api/users.js'

  export default {
    name: "user",
    components: {
      tableEditSelect,
    },
    data() {
      return {
        loading: false,
        tableData: [],
        infoShow: false,
        saveType: 1, // 1新增 2编辑
        dialogTitle: '',
        submitting: false,
        info: {
          staff_id: "",
          roleIds: '',
          mobile: "",
          name: "",
          photo: '',
        },
        roles: [],
        statusList: [{
            id: 0,
            title: "已禁用",
            type: "info",
          },
          {
            id: 1,
            title: "已启用",
            type: "warning",
          },
        ],
        filter: {
          keyword: "",
          status: [],
        },
        roleCascade: [],
        total: 0,
        current_page: 0,
        page_size: 0,

        // 头像相关
        showHeadDialog: false,
        head: '',
        selectedHead: 0,
        userHeads: [
          'https://oss.huarendjk.com/img/2023-04-20/64410946a4bbd.png',
          'https://oss.huarendjk.com/img/2023-04-20/6441095ad5556.png',
          'https://oss.huarendjk.com/img/2023-04-20/6441096698aa9.png',
          'https://oss.huarendjk.com/img/2023-04-20/64410972135d8.png',
          'https://oss.huarendjk.com/img/2023-04-20/6441097e829cb.png',
          'https://oss.huarendjk.com/img/2023-04-20/6441098aae7bc.png',
          'https://oss.huarendjk.com/img/2023-04-20/64410995cf640.png',
          'https://oss.huarendjk.com/img/2023-04-20/644109a08e35c.png',
          'https://oss.huarendjk.com/img/2023-04-20/644109b085a53.png',
          'https://oss.huarendjk.com/img/2023-04-20/644109bbe968e.png'
        ],

        // 编辑密码弹窗数据
        editPassForm: {
          password: '',
          confirm_password: '',
        },
        // 编辑密码弹窗显示状态
        editPassShow: false,
        // 修改密码校验规则
        rules: {
          password: [{
            required: true,
            message: '密码不能为空',
            trigger: 'change'
          }],
          confirm_password: [{
            required: true,
            message: '再次输入密码不能为空',
            trigger: 'change'
          }],
        },
        // 当前登录者id
        systemUserId: 0,
        // 修改密码 提交状态
        editSubmitting: false,
      }
    },
    created() {
      this.systemUserId = sessionStorage.getItem('systemUserId');
    },
    mounted() {
      this.getTabeData()
    },
    methods: {
      // 选择头像按钮
      handleSelectHead() {
        this.showHeadDialog = true
      },
      // 选择头像弹窗的确定按钮
      handleConfirmHead() {
        this.head = this.selectedHead
        this.showHeadDialog = false
      },

      handleCurrentChange(val) {
        this.$util.get("users", {
          page: val
        }).then((res) => {
          this.total = res.total;
          this.current_page = res.current_page;
          this.page_size = res.per_page;
          this.tableData = res.data;
        });
      },
      getTabeData() {
        this.loading = true;
        let data = this.filter;
        this.tableData = [];
        this.$util.get("users", data).then((res) => {
          this.total = res.total;
          this.current_page = res.current_page;
          this.page_size = res.per_page;
          this.loading = false;
          this.tableData = res.data;
        });
      },
      getRoles() {
        this.$util
          .get("slc", {
            model: "Role",
          })
          .then((res) => {
            this.roles = res;
          });
      },
      onSearch() {
        this.page = 1;
        this.getTabeData();
      },
      pageChange(page) {
        this.page = page;
        this.getTableData();
      },
      onCreate() {
        this.infoShow = true;
        this.saveType = 1;
        this.dialogTitle = '新增'
        this.head = this.userHeads[0]
        this.getRoles();
      },
      onEdit(row) {
        this.getRoles();
        this.dialogTitle = '编辑'
        this.saveType = 2;
        let roleIds = [];
        row.roles.forEach((role) => {
          roleIds.push(role.id);
        });
        // 回显头像
        this.head = row.photo
        this.info = {
          id: row.id,
          name: row.name,
          roleIds: roleIds,
          staff_id: row.staff_id,
          mobile: row.mobile,
          photo: row.photo
        };
        this.infoShow = true;
      },
      onInitialize(row) {
        this.$util.put('users/initPassword/' + row.id)
          .then(res => {
            if (res.code == 0) {
              this.getTabeData()
            } else {
              this.$message(res.msg)
            }
          })

      },
      onQuick(id, field, val) {
        this.$util
          .put("users/quick/" + id, {
            field: field,
            value: val,
          })
          .then(() => {
            this.getTabeData();
          });
      },
      subInfo() {
        this.$refs["info"].validate((valid) => {
          if (!valid) return

          this.submitting = true
          this.info.photo = this.head

          if (this.saveType == 1) {
            this.$util.post("users", this.info).then((res) => {
              if (res.code != 0) {
                this.$message(res.msg ? res.msg : "操作失败");
              } else {
                this.getTabeData();
                this.infoShow = false;
              }
            }).finally(() => this.submitting = false)
          } else {
            this.$util.put("users/" + this.info.id, this.info).then(() => {
              this.getTabeData()
              this.infoShow = false;
            }).finally(() => this.submitting = false)
          }
        });
      },
      resetInfo() {
        this.info = {
          staff_id: "",
        };
      },
      filterTable(filters) {
        for (const key in filters) {
          this.filter[key] = filters[key];
        }
        this.getTabeData();
      },
      // 编辑密码
      onEditPass() {
        this.editPassShow = true;
      },
      // 关闭修改密码弹窗
      resetPass() {
        this.editPassShow = false;
        this.editPassForm.password = '';
        this.editPassForm.confirm_password = '';
        this.$refs.editPass.resetFields();
      },
      // 提交修改密码
      handleConfirmEditPass() {
        this.$refs.editPass.validate(isValid => {
          if (!isValid) return
          this.editSubmitting = true;
          editUserPasswordAPI(this.editPassForm).then(() => {
            this.$message.success('修改成功，请重新登录')
            /*
            	退出登录，清除一些数据，同时将活跃路由设置为待办事项
            	（原因是退出登录再重新登录会进入退出之前所在的页面，
            	但是路由却是待办事项的，这里给他固定重新登录进入待办事项）
            */
            setTimeout(() => {
              window.sessionStorage.removeItem("token")
              window.sessionStorage.removeItem("roles")
              window.sessionStorage.removeItem("adminer")
              window.sessionStorage.removeItem("sex")
              this.$store.commit("set_active_index", '/index')
              this.$router.push("/login")
            }, 500)
            // this.resetPass();
            // this.getTabeData();
          }).finally(() => this.editSubmitting = false)
        })
      },
    },
  };
</script>

<style scoped lang="less">
  .head-container {
    margin-bottom: 10px;
    text-align: center;
  }

  .dialog-head-container {
    display: grid;
    grid-template-columns: auto auto auto auto auto;

    .dialog-head-container_item {
      display: flex;
      justify-content: center;
      padding: 10px;
    }

    .dialog-head-container_img {
      display: block;
      box-sizing: border-box;
      border-radius: 50px;
      width: 80px;
      height: 80px;
    }

    .dialog-head-container_img--active {
      border: 1px solid #66b1ff;
    }

    .dialog-head-container_img:hover {
      .dialog-head-container_img--active()
    }
  }

  .head-img {
    width: 80px;
    height: 80px;
  }

  .icon-box {
    display: inline-block;
    padding: 0 15px;
  }

  .icon-list {
    border: #eee 1px solid;
    padding: 0;
    overflow: hidden;
    border-right: none;
    border-bottom: none;
    height: 400px;
    overflow-y: scroll;
  }

  .icon-list li {
    float: left;
    width: 10%;
    list-style: none;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    border-right: #eee 1px solid;
    border-bottom: #eee 1px solid;
  }

  .icon-list li.on {
    background-color: #eee;
  }

  .icon-list li i {
    display: block;
    font-size: 24px;
  }

  .icon-list li span span {
    display: none;
  }

  .row-edit-input {
    margin-bottom: 10px;
  }

  ::v-deep .el-upload-dragger {
    width: 240px;
    height: 145px;
  }

  ::v-deep .el-pagination {
    margin: 20px 0;
    text-align: right;
  }
</style>